/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ConsoleHelper from "../../../helpers/ConsoleHelper";
import { getAuthHeader } from "../../../helpers/Authorization";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";
import * as Constants from "../../../constants";
import DetailsView from "../../ShareComponents/DetailView/DetailsView";
import DetailsViewMobile from "../../ShareComponents/DetailView/DetailsViewMobile";
import "../../../styles/helpers/button4.scss";

const AnnualReportDetails = ({
  vehicle,
  setSelectedInspection,
  inspection,
  setMobileDetails,
  disableMobileVersion,
  detailsReady,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${Constants.MOBILE_BREAKPOINT}px)` });
  const { listAssetTypeChecks } = useSelector((state) => state.apiCallData);
  const [validChecks, setValidChecks] = useState(false);
  const ItemChecks = {
    lifeSaving: [
      "pfd_lifejacket_ledger",
      "throw_bags",
      "heaving_line",
      "liferings",
      "reboarding_device",
      "watertight_flashlight",
      "pyrotechnic_flares",
      "first_aid_kit",
    ],
    mechanical: ["engine_working"],
    communications: ["marine_vhf_radio", "police_radio", "epirb_registered"],
    bilge: ["bilge_pump_clear"],
    steeringControls: ["steering_controls_operable"],
    hatches: ["hatches_operable"],
    emergencyDrills: [
      "training_skills_exercised",
      "drills_entered_logbook",
      "emergency_briefings_conducted",
    ],
    fireExtinguishers: ["fire_extinguishers_inspected"],
    hull: ["regular_inspections_completed"],
    electrical: ["electrical_inspections_completed"],
    navigation: ["broken_cracked_lenses", "lights_functioning"],
    fuel: ["fuel_connections_good", "fuel_containers_stored_properly"],
    anchoring: ["ground_tackle_inspected", "bitter_end_attached", "anchor_secured"],
    guard: ["welds_bolts_screws_good", "grab_lines_good"],
    crew: ["operators_trained", "crew_equipment_trained"],
  };

  const onBack = () => {
    setMobileDetails(false);
    setSelectedInspection(null);
  };

  const isSatisfied = (arr, inspection) => {
    for (let i = 0; i < arr.length; i++) {
      if (inspection[arr[i]] === false) {
        return false;
      }
    }
    return true;
  };

  const listUnsatisfied = (arr, inspection) => {
    let res = [];
    for (let i = 0; i < arr.length; i++) {
      if (inspection[arr[i]] === false) {
        let newRes = [...res, arr[i]];
        res = newRes;
      }
    }
    return res;
  };

  const fetchPDF = () => {
    axios
      .get(`${Constants.ENDPOINT_PREFIX}/api/v1/Assets/Get/AnnualReport/${vehicle.VIN}`, {
        ...getAuthHeader(),
      })
      .then((response) => {
        const inspections = response;
        // console.log("PDF Response: ", inspections);
        const url = inspections.data[inspections.data.length - 1].file_url;
        // Open the PDF in a new window or tab
        window.open(url, "_blank");
      })
      .catch((error) => {
        ConsoleHelper(error);
      });
  };

  useEffect(() => {
    if (vehicle) {
      Object.keys(inspection).forEach((name, i) => {
        if (
          (inspection[name] === true || inspection[name] === false) &&
          listAssetTypeChecks.indexOf(name) > -1
        ) {
          setValidChecks(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  const OpcheckDetails = (
    <div className="main-details">
      <h5 className="op-title font-weight-bold">
        {t("lookupAnnualReportsPanelIndex.visual_op_check")}
      </h5>
      <div className="p-ml-3 table-export-btn" style={{ textAlign: "right" }}>
        <Button type="button" icon="pi pi-external-link" label="View PDF" onClick={fetchPDF} />
      </div>
      {
        <React.Fragment>
          {Object.keys(ItemChecks).map((name, i) => {
            return (
              <div key={i} className="op-values text-white">
                <span key={i} className="p-pb-1 font-weight-bold">
                  {name}:{" "}
                </span>
                <span>
                  {isSatisfied(ItemChecks[name], inspection) ? (
                    <span>{t("lookupAnnualReportsPanelIndex.satisfactory")}</span>
                  ) : (
                    <React.Fragment>
                      <span className="text-danger">
                        {t("lookupAnnualReportsPanelIndex.unsatisfactory")}
                      </span>
                      {listUnsatisfied(ItemChecks[name], inspection).map((title, index) => {
                        return (
                          <span
                            className="p-pb-1 font-weight-bold"
                            style={{ marginLeft: 10, display: "block" }}
                            key={ItemChecks[name] + index}
                          >
                            {t("lookupAnnualReportsPanelIndex." + title)}: {"false"}
                          </span>
                        );
                      })}
                    </React.Fragment>
                  )}
                </span>
              </div>
            );
          })}
        </React.Fragment>
      }
    </div>
  );

  let detailViewTitles = [
    t("general.id"),
    t("lookupAnnualReportPanel.asset_type_label"),
    t("lookupAnnualReportPanel.location_label"),
    t("lookupAnnualReportPanel.date_completed_label"),
    t("lookupAnnualReportPanel.status_label"),
  ];
  let detailViewValues = [
    inspection.id,
    vehicle.asset_type,
    vehicle.current_location,
    moment(inspection.date_created).format("MMM Do, YYYY"),
    inspection.vessel_damaged_since_last_report
      ? t("lookupAnnualReportPanel.damaged")
      : t("lookupAnnualReportPanel.undamaged"),
  ];

  return (
    <React.Fragment>
      {isMobile && !disableMobileVersion ? (
        <div className="p-mx-2 p-my-3">
          <div className="no-style-btn p-my-3">
            <Button
              label={t("general.back")}
              className="p-button-link"
              icon="pi pi-chevron-left"
              onClick={() => onBack()}
            />
          </div>
          <DetailsViewMobile
            header={t("general.header_vin", { vin: vehicle.VIN })}
            titles={detailViewTitles}
            values={detailViewValues}
            additionalDescr={OpcheckDetails}
          />
        </div>
      ) : (
        <DetailsView
          headers={[
            t("lookupAnnualReportPanel.page_detail_title"),
            t("general.header_vin", { vin: vehicle.VIN }),
          ]}
          titles={detailViewTitles}
          values={detailViewValues}
          onHideDialog={setSelectedInspection}
          additionalDescr={OpcheckDetails}
          detailsReady={detailsReady}
        />
      )}
    </React.Fragment>
  );
};

export default AnnualReportDetails;
