import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import * as Constants from "../../../constants";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import InspectionsTable from "./AnnualInspectionsTable";
import PanelHeader from "../../ShareComponents/helpers/PanelHeader";
import QuickAccessTabs from "../../ShareComponents/QuickAccessTabs";
import VINSearch from "../../ShareComponents/helpers/VINSearch";
import "../../../styles/ShareComponents/Table/table.scss";

const LookupAnnualReportsPanel = () => {
  const { t } = useTranslation();
  const [vehicle, setVehicle] = useState(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${Constants.MOBILE_BREAKPOINT}px)` });

  return (
    <div>
      {isMobile && (
        <QuickAccessTabs
          tabs={["Annual Report", "Lookup"]}
          activeTab={"Lookup"}
          urls={["/operators/annual-report", "/operators/lookup-annual-report"]}
        />
      )}
      <PanelHeader icon={faCalendarCheck} text={t("lookup Annual Reports")} mobileBg />
      {!isMobile && (
        <QuickAccessTabs
          tabs={["Annual Report", "Lookup"]}
          activeTab={"Lookup"}
          urls={["/operators/annual-report", "/operators/lookup-annual-report"]}
        />
      )}
      <div className={`VIN-cont ${isMobile ? "p-pb-4" : "p-mt-5"}`}>
        <VINSearch
          onVehicleSelected={(v) => {
            setVehicle(v);
          }}
        />
      </div>
      <div className={`${isMobile ? "p-mt-3 p-mb-5" : "p-mt-5"}`}>
        <InspectionsTable vehicle={vehicle} />
      </div>
    </div>
  );
};

export default LookupAnnualReportsPanel;
