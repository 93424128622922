/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { CTRL_AUDIO_PLAY } from "../../../redux/types/audioTypes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Slider } from "primereact/slider";
import { RadioButton } from "primereact/radiobutton";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import * as Constants from "../../../constants";
import ConsoleHelper from "../../../helpers/ConsoleHelper";
import { getAuthHeader } from "../../../helpers/Authorization";
import GeneralRadio from "../../ShareComponents/GeneralRadio";
import GeneralRadioForm from "../../ShareComponents/GeneralRadioForm";
import ChecklistItem from "../../ShareComponents/ChecklistItem";
import CardWidget from "../../ShareComponents/CardWidget";
import WarningMsg from "../../ShareComponents/WarningMsg";
import Tooltip from "../../ShareComponents/Tooltip/Tooltip";
import RadioComment from "../../ShareComponents/RadioComment";
import SelectComment from "../../ShareComponents/SelectComment/SelectComment";
import "../../../styles/helpers/button1.scss";
import "../../../styles/OperatorsPanel/OpCheckForm.scss";
import template from "./template.pdf";
import { errorAlert, successAlert, loadingAlert } from "../../ShareComponents/CommonAlert";
// positions:
// life saving:

const AnReportForm = ({
  selectedTitle,
  vehicle,
  disableButton,
  validationSearch,
  validationWarningMsg,
  checklistStatus,
  checkFormItems,
  setCheckFormItems,
  checkFormComments,
  setCheckFormComments,
  submitClicked,
  setSubmitClicked,
  checkItems,
  exportPdfFunction,
  setExportPdfFunction,
  setAllFunction,
  resetForm,
  setSetAllFunction,
}) => {
  const dispatch = useDispatch();
  const [damaged, setDamaged] = useState(true);
  const [pdfItems, setPdfItems] = useState({});
  const [pdfBytes, setPdfBytes] = useState(null);
  const { t } = useTranslation();
  const isBigScreen = useMediaQuery({ query: "(min-width: 769px)" });
  const lifeSaving = [
    "pfd_lifejacket_ledger",
    "throw_bags",
    "heaving_line",
    "liferings",
    "reboarding_device",
    "watertight_flashlight",
    "pyrotechnic_flares",
    "first_aid_kit",
  ];
  const mechanical = ["engine_working"];
  const communications = ["marine_vhf_radio", "police_radio", "epirb_registered"];
  const bilge = ["bilge_pump_clear"];
  const steeringControls = ["steering_controls_operable"];
  const hatches = ["hatches_operable"];
  const emergencyDrills = [
    "training_skills_exercised",
    "drills_entered_logbook",
    "emergency_briefings_conducted",
  ];
  const fireExtinguishers = ["fire_extinguishers_inspected"];
  const hull = ["regular_inspections_completed"];
  const electrical = ["electrical_inspections_completed"];
  const navigation = ["broken_cracked_lenses", "lights_functioning"];
  const fuel = ["fuel_connections_good", "fuel_containers_stored_properly"];
  const anchoring = ["ground_tackle_inspected", "bitter_end_attached", "anchor_secured"];
  const guard = ["welds_bolts_screws_good", "grab_lines_good"];
  const crew = ["operators_trained", "crew_equipment_trained"];

  const toLabel = (ele) => {
    const translated = t("lookupAnnualReportsPanelIndex." + ele);
    return [translated];
  };
  const toItems = (arr) => {
    return arr.map((ele, index) => {
      function setValue(answer) {
        setCheckFormItems({ ...checkFormItems, [ele]: answer });
      }
      return {
        value: checkFormItems[ele],
        setValue: setValue,
        name: ele,
        labels: toLabel(ele),
        isTextField: ele === "pfd_lifejacket_ledger" ? true : false,
      };
    });
  };
  function setDamage(answer) {
    setCheckFormItems({ ...checkFormItems, vessel_damaged_since_last_report: answer });
  }
  function setChanges(answer) {
    setCheckFormItems({ ...checkFormItems, structural_changes_made_since_last_report: answer });
  }

  //   [
  //     {
  //       value: lifeSavingAnswers[0],
  //       setValue: setValue,
  //       name: "dbName",
  //       labels: [...labels],
  //     },
  //   ];

  const lifeSavingItems = toItems(lifeSaving);
  const mechanicalItems = toItems(mechanical);
  const communicationsItems = toItems(communications);
  const bilgeItems = toItems(bilge);
  const steeringControlsItems = toItems(steeringControls);
  const hatchesItems = toItems(hatches);
  const emergencyDrillsItems = toItems(emergencyDrills);
  const fireExtinguishersItems = toItems(fireExtinguishers);
  const hullItems = toItems(hull);
  const electricalItems = toItems(electrical);
  const navigationItems = toItems(navigation);
  const fuelItems = toItems(fuel);
  const anchoringItems = toItems(anchoring);
  const guardItems = toItems(guard);
  const crewItems = toItems(crew);

  const itemLabelsList = [
    { label: "lifesaving_equipment", item: lifeSavingItems },
    { label: "engine_and_mechanical", item: mechanicalItems },
    { label: "communications", item: communicationsItems },
    { label: "bilge_pump", item: bilgeItems },
    { label: "steering_controls", item: steeringControlsItems },
    { label: "hatches_operable", item: hatchesItems },
    { label: "emergency_drills", item: emergencyDrillsItems },
    { label: "fire_extinguishers", item: fireExtinguishersItems },
    { label: "hull_and_structure", item: hullItems },
    { label: "electrical", item: electricalItems },
    { label: "navigation_lights", item: navigationItems },
    { label: "fuel_systems", item: fuelItems },
    { label: "anchoring_system", item: anchoringItems },
    { label: "guard", item: guardItems },
    { label: "crew_qualifications", item: crewItems },
  ];

  // let isItemsCheckDisplayed = false;
  // for (const item in checkItems) {
  //   isItemsCheckDisplayed = checkItems[item] || isItemsCheckDisplayed;
  // }

  const pdfLocationMap = {
    damaged: [467, 535],
    changes: [467, 517],
    lifeSaving: [280, 447],
    mechanical: [280, 372],
    communications: [280, 353],
    bilge: [280, 315],
    steering: [280, 285],
    hatches: [280, 265],
    drills: [280, 245],
    fireExtinguishers: [280, 202],
    hull: [555, 448],
    electrical: [555, 408],
    navigation: [555, 370],
    fuel: [555, 330],
    anchoring: [555, 292],
    guard: [555, 244],
    crew: [555, 214],
  };

  const satisfied = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (checkFormItems[arr[i]] === undefined && arr[i] !== "vessel_damaged_since_last_report") {
        continue;
      }
      if (!checkFormItems[arr[i]]) {
        return false;
      }
    }
    return true;
  };

  // Testing only
  const setAll = () => {
    setCheckFormItems({
      pfd_lifejacket_ledger: "NA",
      vessel_damaged_since_last_report: true,
      structural_changes_made_since_last_report: true,
      throw_bags: true,
      heaving_line: true,
      liferings: true,
      reboarding_device: true,
      watertight_flashlight: true,
      pyrotechnic_flares: true,
      first_aid_kit: true,
      engine_working: true,
      marine_vhf_radio: true,
      police_radio: true,
      epirb_registered: true,
      bilge_pump_clear: true,
      steering_controls_operable: true,
      hatches_operable: true,
      training_skills_exercised: true,
      drills_entered_logbook: true,
      emergency_briefings_conducted: true,
      fire_extinguishers_inspected: true,
      regular_inspections_completed: true,
      electrical_inspections_completed: true,
      broken_cracked_lenses: true,
      lights_functioning: true,
      fuel_connections_good: true,
      fuel_containers_stored_properly: true,
      ground_tackle_inspected: true,
      bitter_end_attached: true,
      anchor_secured: true,
      welds_bolts_screws_good: true,
      grab_lines_good: true,
      operators_trained: true,
      crew_equipment_trained: true,
    });
  };

  const generatePDF = async () => {
    // Testing: edit pdf and export

    // This should be a Uint8Array or ArrayBuffer
    // This data can be obtained in a number of different ways
    // If your running in a Node environment, you could use fs.readFile()
    // In the browser, you could make a fetch() call and use res.arrayBuffer()
    const existingPdfBytes = await fetch(template).then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();

    for (const key in pdfItems) {
      // console.log(key + " " + pdfLocationMap[key]);
      const falseAdd = key === "damaged" || key === "changes" ? 65 : 17;
      const right = pdfItems[key] ? true : false;
      const x = right ? pdfLocationMap[key][0] : pdfLocationMap[key][0] + falseAdd;
      firstPage.drawText("X", {
        x: x,
        y: pdfLocationMap[key][1],
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    }

    // console.log("PDF generated");

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const bytes = await pdfDoc.save();
    return bytes;
  };

  const editPDF = async (bytes) => {
    // For example, `pdfBytes` can be:
    //   ? Written to a file in Node
    //   ? Downloaded from the browser
    //   ? Rendered in an <iframe>
    var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "template.pdf";
    link.click();
  };

  const exportPDF = async () => {
    const bytes = await generatePDF();
    // console.log(bytes);
    editPDF(bytes);
  };

  const handleUploadData = (files) => {
    dispatch({ type: CTRL_AUDIO_PLAY, payload: "submit" });
    let newFiles = new FormData();
    let required_file_specs = {
      file_info: [],
    };
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        required_file_specs.file_info.push({
          file_name: vehicle.VIN + " annual report",
          purpose: "other",
        });
        newFiles.append("files", files[i]);
      }
      newFiles.append("file_specs", JSON.stringify(required_file_specs));
    }

    handleSubmit(newFiles);
  };

  const handleSubmit = (data) => {
    loadingAlert();
    axios
      .post(
        `${Constants.ENDPOINT_PREFIX}/api/v1/Assets/Add/AnnualReport/${vehicle.VIN}`,
        data,
        getAuthHeader()
      )
      .then((res) => {
        // console.log("Successful) Response: ", res);
        successAlert(
          "msg",
          t("lookupAnnualReportsPanelIndex.submit_check_success"),
          true,
          "Export PDF",
          exportPdfFunction
        );
        resetForm(null);
        dispatch({ type: CTRL_AUDIO_PLAY, payload: "success_alert" });
      })
      .catch((error) => {
        ConsoleHelper(error);
        errorAlert(error.customErrorMsg, null);
        dispatch({ type: CTRL_AUDIO_PLAY, payload: "error_alert" });
      });
  };

  const generateAndUploadPDF = async () => {
    try {
      const pdfBytes = await generatePDF();

      if (!vehicle || !vehicle.VIN) {
        console.error("Vehicle or VIN is not available");
        return;
      }

      const fileName = `${vehicle.VIN} annual report`;
      const file = new File([pdfBytes], fileName, {
        type: "application/pdf",
      });

      handleUploadData([file]);
    } catch (error) {
      console.error("An error occurred while generating and uploading PDF:", error);
    }
  };

  useEffect(() => {
    if (submitClicked) {
      generateAndUploadPDF();
    }
  }, [submitClicked]);

  useEffect(() => {
    setDamaged(checkFormItems["vessel_damaged_since_last_report"]);
    setExportPdfFunction(() => exportPDF);
    setSetAllFunction(() => setAll);
  }, [checkFormItems]);

  useEffect(() => {
    setPdfItems({
      damaged: satisfied(["vessel_damaged_since_last_report"]),
      changes: satisfied(["structural_changes_made_since_last_report"]),
      lifeSaving: satisfied(lifeSaving),
      mechanical: satisfied(mechanical),
      communications: satisfied(communications),
      bilge: satisfied(bilge),
      steering: satisfied(steeringControls),
      hatches: satisfied(hatches),
      drills: satisfied(emergencyDrills),
      fireExtinguishers: satisfied(fireExtinguishers),
      hull: satisfied(hull),
      electrical: satisfied(electrical),
      navigation: satisfied(navigation),
      fuel: satisfied(fuel),
      anchoring: satisfied(anchoring),
      guard: satisfied(guard),
      crew: satisfied(crew),
    });
  }, [checkFormItems]);

  return (
    <div className="op-check-form p-mt-5">
      <h4 className="p-mb-3">
        {`${selectedTitle} [${t("general.asset_type")}: ${vehicle.asset_type}]`}
      </h4>
      <ChecklistItem
        value={checkFormItems["vessel_damaged_since_last_report"]}
        onChange={setDamage}
        name={"damagedRadio"}
        labels={[t("lookupAnnualReportsPanelIndex.vessel_damaged_since_last_report")]}
        status={
          checkFormItems["vessel_damaged_since_last_report"] ||
          checkFormItems["vessel_damaged_since_last_report"] === false
        }
      />
      {/* {damaged === true && (
        <div className="p-mt-5 p-d-flex p-jc-center btn-1">
          <Link
            to={{
              pathname: "/issues/new",
              query: { vehicle: vehicle },
            }}
            className="no-underline"
          >
            <Button label={t("lookupAnnualReportsPanelIndex.report_new_issue_btn")} />
          </Link>
        </div>
      )} */}
      {
        <ChecklistItem
          value={checkFormItems["structural_changes_made_since_last_report"]}
          onChange={setChanges}
          name={"walkAroundRadio"}
          labels={[t("lookupAnnualReportsPanelIndex.structural_changes_made_since_last_report")]}
          status={
            checkFormItems["structural_changes_made_since_last_report"] ||
            checkFormItems["structural_changes_made_since_last_report"] === false
          }
        />
      }

      <br />

      {itemLabelsList.map((ele, index) => {
        return (
          <GeneralRadioForm
            header={t("lookupAnnualReportsPanelIndex." + ele.label)}
            items={ele.item}
            key={ele.label + "_" + index}
          />
        );
      })}

      {
        <div className="p-mt-5 p-mb-5 p-pb-5">
          {/* {submitClicked && (
            <div className="p-d-flex p-jc-center p-mb-2">
              <h6 className="p-error p-d-block">
                {t("lookupAnnualReportsPanelIndex.comments_required_warning_msg")}
              </h6>
            </div>
          )} */}
          <div className="btn-1 p-d-flex p-jc-center">
            <Button
              type="submit"
              disabled={disableButton || validationSearch}
              label={t("lookupAnnualReportsPanelIndex.submit_annual_report_btn")}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default AnReportForm;
