/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ToggleButton } from "primereact/togglebutton";
import RangeSliderInput from "./RangeSliderInput";
import CustomInputText from "../CustomInputText";
import useWindowSize from "../helpers/useWindowSize";
import * as Constants from "../../../constants";
import LoadingAnimation from "../LoadingAnimation";
import { isMobileDevice, capitalize, validateEmail } from "../../../helpers/helperFunctions";
import { CTRL_AUDIO_PLAY } from "../../../redux/types/audioTypes";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getAuthHeader } from "../../../helpers/Authorization";
import ConsoleHelper from "../../../helpers/ConsoleHelper";
import { MultiSelect } from "primereact/multiselect";
import "../../../styles/ShareComponents/datePicker.scss";
import "../../../styles/ShareComponents/Table/table.scss";
import logoBase64 from "../../../assets/base64/logo";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import "../../../styles/helpers/button2.scss";

/**
 * Share table component with pagination and selection controls
 *
 * @param {Array} tableHeaders table column header title array as format [{header:"", colFilter: {filterField:"", filterOptions: {filterType:"", dateFormat:"", filterElement:object}}}] filterType have options: "dropdown", "date", "dateRange" and "custom"
 * @param {Array} tableData Table data array as format [{id:"", dataPoint: {} ,cells:[]}]
 * @param {Boolean} hasSelection Table have selection control or not
 * @param {Boolean} multipleSelection Table has multiple selection (you can select multiple rows)
 * @param {Function} onSelectionChange Call back on selection changed, the param will be {dataPoint}
 * @param {Object} pagination Table pagination params as format {totalPages:10, currentPage:1, onPageChangeHandle:callback}
 * @param {Boolean} dataReady Determine if the table will display spinner or not, default to true
 */
const Table = ({
  tableHeaders = [],
  tableData = [],
  onSelectionChange,
  hasSelection = false,
  multipleSelection = false,
  dataReady = true,
  hasExport = false,
  hasPdfExport = false,
  exportPdfColumns = [],
  rows,
  globalSearch = true,
  disableMobileDetail = false,
  tab = 0,
  persistPage = true,
  timeOrder = true,
  showSearchPref = true,
}) => {
  const [first, setFirst] = useState(0);
  const [realTableData, setRealTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [active, setActive] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(multipleSelection ? [] : null);
  const selectionMode = hasSelection ? (multipleSelection ? "multiple" : "single") : null;
  const [filters, setFilters] = useState({});
  const [globalFilter, setGlobalFilter] = useState(null);
  const dispatch = useDispatch();
  const dtRef = useRef(null);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const size = useWindowSize();
  let isMobile = size.width <= Constants.MOBILE_BREAKPOINT;
  let isSMobile = size.width <= Constants.SMALL_MOBILE_BREAKPOINT;
  let tableFilters = tableHeaders.map((item) => item.colFilter);
  const [saveFilterDialog, setSaveFilterDialog] = useState(false);
  const [searchPreference, setSearchPreference] = useState(null);
  const [searchPreferenceName, setSearchPreferenceName] = useState("");
  const [preferencesArray, setPreferencesArray] = useState([]);
  const [deleteFilterDialog, setDeleteFilterDialog] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [eventTypeFilter, setEventTypeFilter] = useState([]);
  const [theAllFilter, setTheAllFilter] = useState(true);
  const history = useHistory();

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [maxPriceRange, setMaxPriceRange] = useState(0);

  useEffect(() => {
    setRealTableData(tableData);
    // PriceFilterElement();
    setMinPrice(Math.min(...tableData.map((el) => el.dataPoint.total_cost)));
    setMaxPriceRange(Math.max(...tableData.map((el) => el.dataPoint.total_cost)));
    setMaxPrice(Math.max(...tableData.map((el) => el.dataPoint.total_cost)));
    setTotal(tableData?.length || 0);
  }, [tableData]);

  const deletepreference = () => {
    const list = [];
    preferencesArray.map((item) => list.push(item.label));
    setEventTypes(list);
    setDeleteFilterDialog(true);
  };

  const onPageHandler = (e) => {
    if (active || e.first !== 0) {
      setFirst(e.first);
      if ("URLSearchParams" in window && persistPage) {
        let searchParams = new URLSearchParams(window.location.search);
        if (tab === 0) searchParams.set("page1", e.first);
        else searchParams.set("page2", e.first);
        let newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
        history.replace(newRelativePathQuery);
      }
    }
    setActive(true);
  };

  useEffect(() => {
    if ("URLSearchParams" in window && persistPage) {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = tab === 0 ? urlParams.get("page1") : urlParams.get("page2");
      if (myParam) setFirst(parseInt(myParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  //get table wrapper inital height and set as min height to avoid jumping
  useEffect(() => {
    if (!isMobileDevice()) {
      let initialHeight = wrapperRef.current.clientHeight;
      wrapperRef.current.style.minHeight = `${initialHeight}px`;
    }
  }, [dataReady]);

  //Reset state every time table is rerendered (via dataReady)
  useEffect(() => {
    setSelectedRowData(multipleSelection ? [] : null);
  }, [dataReady, multipleSelection]);

  const onSelectHandle = (event) => {
    setActive(true);
    setSelectedRowData(event.value);
    dispatch({ type: CTRL_AUDIO_PLAY, payload: "table_detail" });

    let dataPoint = null;
    if (!multipleSelection) {
      //Single select logic
      if (!!event.value) {
        dataPoint = event.value.dataPoint;
      }
    } else {
      //Multiple Select logic
      dataPoint = event.value.map((rowData) => rowData.dataPoint);
    }
    onSelectionChange(dataPoint);
  };

  const bodyTemplate = (data, props) => {
    return (
      <React.Fragment>
        {/* This line causes repetitive table header in table rows. Not certain if deleting it has other unwanted effects. */}
        <span className="p-column-title">{props.header}</span>
        <span className="p-body-template">{data.cells[props.field]}</span>
      </React.Fragment>
    );
  };

  const filterFunction = (colIndex) => {
    let filterOption = tableFilters[colIndex]?.filterOptions;
    switch (filterOption?.filterAs) {
      case "dateRange":
      case "dateRangeFuel":
        return (value, filter) => {
          let val = moment(value);

          let startDate = !!filter[0] ? moment(filter[0]) : undefined;
          let endDate = !!filter[1] ? moment(filter[1]) : undefined;
          return val.isBetween(startDate, endDate, undefined, "[]");
        };
      case "priceRange":
        return (value, filter) => {
          return value >= filter.minPrice && value <= filter.maxPrice;
        };
      default:
        return filterOption?.filterFunction;
    }
  };

  const filterElement = (colIndex) => {
    let filterOption = tableFilters[colIndex]?.filterOptions;
    switch (filterOption?.filterAs) {
      case "dropdown":
        return SelectFilterElement(colIndex);
      case "date":
        return DateFilterElement(colIndex, filterOption.dateFormat);
      case "dateRangeFuel":
      case "dateRange":
        return DateFilterElement(colIndex, filterOption.dateFormat, true);
      case "priceRange":
        return PriceFilterElement(colIndex);
      default:
        return tableFilters[colIndex]?.filterOptions?.filterElement;
    }
  };

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        panelClassName="table-cal-dropdown"
        className="p-mx-1 w-auto"
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        panelClassName="table-cal-dropdown"
        className="p-mx-1 w-auto"
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  useEffect(() => {
    if (!isMobileDevice() && !isMobile) {
      dtRef.current.filter(
        { minPrice, maxPrice },
        tableHeaders.findIndex((header) => header.header === "Cost of Ownership"),
        "custom"
      );
    }
  }, [minPrice, maxPrice]);
  //

  const PriceFilterElement = (colIndex) => {
    return (
      <RangeSliderInput
        minValue={minPrice}
        maxValue={maxPrice}
        setMinValue={setMinPrice}
        setMaxValue={setMaxPrice}
        valueGap={1000}
        max={maxPriceRange + 1000}
        buffer={1000}
      />
    );
  };

  const DateFilterElement = (colIndex, dateFormat, isRange = false) => {
    const today = new Date();
    let filterOption = tableFilters[colIndex]?.filterOptions.filterAs;
    return (
      <div className="table-calendar">
        <Calendar
          panelClassName="dropdown-content-cal"
          className="w-100"
          placeholder={t("general.filter_placeholder", { name: tableHeaders[colIndex].header })}
          value={filters[tableFilters[colIndex]?.field]}
          showButtonBar
          showIcon
          monthNavigator
          yearNavigator
          monthNavigatorTemplate={monthNavigatorTemplate}
          yearNavigatorTemplate={yearNavigatorTemplate}
          yearRange="1980:2099"
          maxDate={filterOption === "dateRangeFuel" ? today : false}
          selectionMode={isRange ? "range" : "single"}
          dateFormat="yy/mm/dd" // Change the date format here
          onChange={(e) => {
            let selectedDate = e.target.value;

            let ColFilterField = tableFilters[colIndex].field;
            selectedDate = isRange
              ? selectedDate
              : selectedDate && moment(selectedDate).format("YYYY/MM/DD"); // Change the format here

            setFilters({ ...filters, [ColFilterField]: selectedDate });
            dtRef.current.filter(selectedDate, colIndex, isRange ? "custom" : "equals");
          }}
        />
      </div>
    );
  };

  const SelectFilterElement = (colIndex) => {
    let colFilterOption = tableFilters[colIndex]?.filterOptions;
    let colFilterField = tableFilters[colIndex]?.field;
    const statusType = {
      all: t("general.all"),
      operable: t("lookupDailyCheckPanel.op"),
      inopoperable: t("lookupDailyCheckPanel.inop"),
    };

    const statusItemTemplate = (value) => {
      return !value ? statusType.operable : statusType.inopoperable;
    };
    let valueArray = [];
    tableData.forEach((rowData) => {
      let value = rowData.dataPoint[colFilterField];

      if (valueArray.indexOf(value) < 0) valueArray.push(value);
    });

    let options = valueArray.map((value) => {
      if (colFilterField === "is_preventable") {
        if (value) {
          return { name: t("incidentsDetails.preventable") };
        } else {
          return { name: t("incidentsDetails.non_preventable") };
        }
      } else if (colFilterField === "is_tagout") {
        return { name: statusItemTemplate(value) };
      }
      return { name: value };
    });

    // Trim instances of {} and {name: null} or {name: ''}
    options.forEach((e) => {
      for (let value in e) {
        if (e[value] === null || e[value] === "") {
          delete e[value];
        }
      }
    });
    options = options.filter((e) => Object.keys(e).length !== 0);

    let customItemTemplate;
    let customValueTemplate;
    if (colFilterField === "is_preventable" || colFilterField === "is_tagout") {
      customItemTemplate = (option) => {
        if (option) {
          return option.name;
        }
      };

      customValueTemplate = (option, props) => {
        if (option) {
          return option.name;
        }
      };
    } else {
      customItemTemplate = (option) => {
        if (option) {
          return !!colFilterOption?.itemTemplate
            ? colFilterOption.itemTemplate(option.name)
            : validateEmail(option.name)
            ? option.name
            : capitalize(option.name);
        }
      };

      customValueTemplate = (option, props) => {
        if (option)
          return !!colFilterOption?.valueTemplate
            ? colFilterOption.valueTemplate(option.name)
            : validateEmail(option.name)
            ? option.name
            : capitalize(option.name);

        return <span>uuu</span>;
      };
    }

    return (
      <MultiSelect
        placeholder={t("general.filter_placeholder", { name: tableHeaders[colIndex].header })}
        panelClassName="table-filter-dropdown"
        options={options}
        optionLabel="name"
        className="p-column-filter"
        value={filters[colFilterField]}
        itemTemplate={customItemTemplate}
        valueTemplate={customValueTemplate}
        showClear
        filter={options.length > 10}
        filterBy="name"
        onChange={(e) => {
          let selectedFilters = e.target.value;
          let filterAS = tableFilters[colIndex]?.filterOptions.filterAs;
          let filterValue = [];
          if (selectedFilters != null) {
            filterValue = selectedFilters.map((a) => {
              if (a.name === "Operable") {
                return false;
              } else if (a.name === "Non-Operable") {
                return true;
              } else if (a.name === "Preventable") {
                return true;
              } else if (a.name === "Non-Preventable") {
                return false;
              } else {
                return a.name;
              }
            });
          }

          setFilters({ ...filters, [colFilterField]: selectedFilters });
          dtRef.current.filter(filterValue, colIndex, "in");
        }}
      />
    );
  };

  let dynamicColumns = tableHeaders.map((col, colIndex) => {
    //TODO remove this logic after finish all table refactoring
    let colName = typeof col === "string" ? col : col.header;

    return (
      <Column
        key={colIndex}
        field={`${colIndex}`}
        header={colName}
        body={bodyTemplate}
        filter={!!tableFilters[colIndex]}
        filterPlaceholder={t("general.filter_placeholder", { name: colName })}
        filterElement={filterElement(colIndex)}
        filterFunction={filterFunction(colIndex)}
        filterField={`${colIndex}`}
        filterType="search"
        filterMatchMode={
          tableFilters[colIndex]?.filterOptions?.filterFunction ? "custom" : "contains"
        }
        sortable
        style={{ whiteSpace: "pre-wrap" }}
      />
    );
  });

  let list =
    tableData &&
    tableData.map((item) => {
      const isPreventable = item?.dataPoint?.is_preventable
        ? t("incidentsDetails.preventable")
        : t("incidentsDetails.non_preventable");

      // Create a new object with the modified is_preventable value
      const modifiedDataPoint = {
        ...item.dataPoint,
        is_preventable: isPreventable,
      };

      let data = tableFilters.map((filter, colIndex) => {
        if (!!tableFilters[colIndex]) return modifiedDataPoint[filter.field];
        else return item.cells[colIndex];
      });

      return {
        id: item.id,
        dataPoint: modifiedDataPoint,
        cells: item.cells,
        ...data,
      };
    });

  if (list.length !== 0) {
    if (list[0].dataPoint.date_created && timeOrder) {
      list.sort(
        (data1, data2) =>
          new Date(data1.dataPoint.date_created).getTime() -
          new Date(data2.dataPoint.date_created).getTime()
      );
    }
  }

  const exportCSV = () => {
    dtRef.current.exportCSV();
  };

  const handlePdfExport = (dataSource = [], columns = []) => {
    const { apiCallData } = JSON.parse(localStorage.getItem("persist:root"));
    const { userInfo } = JSON.parse(apiCallData);
    const { user } = userInfo || {};
    const head = [columns?.map((column) => column.title)];
    {dataSource?.reduce((pre, cur, index) =>
        []);}
    const body = dataSource?.reduce((pre, cur, index) => {
      const dataMap = [];
      for (const column of columns) {
        dataMap.push(cur?.dataPoint?.[column?.dataIndex] || "-");
      }
      pre.push(dataMap);
      return pre;
    }, []);

    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: [8, 92, 116] },
    });

    const doc = new jsPDF("landscape", "mm", [500, 210]);
    doc.text("Vessel Summary Report", 14, 28);
    doc.setFontSize(11);
    doc.setTextColor(100);
    doc.text(`Date of the Report : ${moment().format("YYYY-MM-DD")}`, 14, 40);
    doc.text(`Prepared By : ${user?.first_name} ${user?.last_name}`, 14, 48);
    doc.text(`Total Vessels : ${total}`, 14, 56);
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const base64Img = logoBase64;
    doc.addImage(base64Img, "png", pageWidth - 54, 15, 40, 15);

    autoTable(doc, {
      startY: 61,
      head: head,
      body: body,
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      showHead: "firstPage",
    });
    doc.save(`Vessel Summary Report ${moment().format("YYYY-MM-DD HHmm")} Local Time.pdf`);
  };

  const tableToolsHeader = (
    <React.Fragment>
      {globalSearch && (
        <>
          <div className="table-tools-header p-fluid">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                onInput={(e) => setGlobalFilter(e.target.value)}
                placeholder={t("general.table_global_filter_placeholder")}
                tooltip={t("general.table_global_filter_tooltip")}
                tooltipOptions={{ position: "top" }}
              />
            </span>
            {hasExport && (
              <div className="p-ml-3 table-export-btn" style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  icon="pi pi-external-link"
                  label="Export"
                  onClick={exportCSV}
                />
              </div>
            )}
          </div>
        </>
      )}
      <div className="table-tools-header-total-col">
        <span>Total Vessels ：{total || 0}</span>
        {hasPdfExport && (
          <div className="p-ml-3 table-export-btn" style={{ textAlign: "right" }}>
            <Button
              type="button"
              icon="pi pi-external-link"
              label="Export PDF"
              onClick={() => handlePdfExport(realTableData, exportPdfColumns)}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );

  const getAllPreferences = () => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${Constants.ENDPOINT_PREFIX}/api-auth/v1/User/Info`, {
        ...getAuthHeader(),
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        let arr = [];
        Object.entries(response.data.user_config.table_filter).forEach((e) => {
          // here we are just adding a 'useless' key to make the search pref unique
          // should be deleted below or there will be problem
          e[1]["useless"] = Math.floor(Math.random() * 100);
          arr.push({ label: e[0], value: e[1] });
        });
        setPreferencesArray(arr);
      })
      .catch((err) => {
        ConsoleHelper(err);
      });
  };

  useEffect(() => {
    getAllPreferences();
  }, []);

  const saveFilterOption = (name) => {
    //save current filter selection with API call
    //filters are stored in an object called "filters", seen below
    let payload = {};
    payload[name] = filters;
    for (let filterName in payload[name]) {
      if (payload[name][filterName] === null) {
        delete payload[name][filterName];
      }
      // IMPORTANT: backend currently cannot handle null value for date array, so set the end date to current date if only single date selected
      else if (payload[name][filterName][0] instanceof Date) {
        if (!payload[name][filterName][1]) {
          // If single date, set filter to only that date
          let start = new Date(payload[name][filterName][0].getTime());
          let end = new Date(payload[name][filterName][0].getTime());
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 0);
          payload[name][filterName] = [start, end];
        }
        payload[name][filterName] = payload[name][filterName].map((ele) =>
          ele == null ? new Date() : ele
        );
      }
    }
    axios
      .post(
        `${Constants.ENDPOINT_PREFIX}/api-auth/v1/User/Update/Tablefilter`,
        payload,
        getAuthHeader()
      )
      .then((res) => {
        //update the new value when submit success
        getAllPreferences();
      })
      .catch((error) => {
        ConsoleHelper(error);
      });
  };

  const deleteFilterOption = (list) => {
    var payload = {};
    for (let i = 0; i < list.length; i++) {
      payload[list[i]] = {};
    }
    axios
      .post(
        `${Constants.ENDPOINT_PREFIX}/api-auth/v1/User/Delete/Tablefilter`,
        payload,
        getAuthHeader()
      )
      .then((res) => {
        //update the new value when submit success
        getAllPreferences();
      })
      .catch((error) => {
        ConsoleHelper(error);
      });
  };

  //update the data in the table according to the filters specified by selectedDropdownFilter, used only for the search preference dropdown
  const updateFilters = (selectedDropdownFilter) => {
    let filterApplied = false;
    // check for non null value
    if (Object.keys(selectedDropdownFilter).length > 0) {
      //reset the current applied filters
      dtRef.current.state.filters = {};
      for (const columnType in selectedDropdownFilter) {
        let columnFilterValues = [];
        //if key is not null
        if (selectedDropdownFilter[columnType]) {
          let columnVal = selectedDropdownFilter[columnType];
          if (!Array.isArray(columnVal)) {
            columnVal = [columnVal];
          }
          columnFilterValues = columnVal.map((a) => {
            if (a) {
              return a instanceof Date ? a : a.name;
            } else {
              return null;
            }
          });
        }
        //if value is not null
        if (columnFilterValues) {
          //find the index of the column in the table that matches the column type specified
          const columnIndex = tableHeaders.findIndex((a) => a.colFilter.field === columnType);
          //update the table ref
          // let add = { value: columnFilterValues, matchMode: "in" };
          // dtRef.current.state.filters[columnIndex] = add;
          if (columnFilterValues.length !== 0) {
            filterApplied = true;
            if (columnFilterValues[0] instanceof Date) {
              dtRef.current.filter(columnFilterValues, columnIndex, "custom");
            } else {
              dtRef.current.filter(columnFilterValues, columnIndex, "in");
            }
          } else {
            if (!filterApplied) {
              // no filter applied, just clear it
              dtRef.current.filter([], -1, "in");
            }
          }
        }
      }
    }
  };

  const handleValueChange = (values) => {
    setRealTableData(values || []);
    setTotal(values?.length || 0);
  };

  useEffect(() => {
    {
      /* update the filters when the page is loaded, will need to be updated when a new search preference is saved too */
    }
    updateFilters(filters);
  }, []);

  // The following codes are written by an intern, please feel free to edit if you have better ways to handle saving dates as filter options

  // Test whether an object is a date
  const isIsoDate = (str) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
  };
  // convert date strings in selectedDropDownFilter to actual dates
  const filterToDate = (selectedDropdownFilter) => {
    Object.keys(selectedDropdownFilter).forEach(function (key, index) {
      let ele = selectedDropdownFilter[key];
      if (isIsoDate(ele[0])) {
        // IMPORTANT: backend currently cannot handle null value for date array, so set the end date to current date if only single date selected
        selectedDropdownFilter[key] = ele.map((e, i) => {
          return e ? new Date(e) : new Date();
        });
      }
    });
    return { ...selectedDropdownFilter };
  };

  const filterButtons = eventTypes.map((eventType, i) => {
    return (
      <ToggleButton
        key={eventType + i}
        checked={checkedFilters[i]}
        onChange={() => filtersHandler(eventType, i)}
        onLabel={capitalize(eventType)}
        offLabel={capitalize(eventType)}
        onIcon=""
        offIcon=""
        className="p-mr-2 p-mb-2 p-button-rounded p-center align-items-center"
      />
    );
  });

  const filtersHandler = (eventType, i) => {
    let copyOfCheckedFilters = [...checkedFilters];
    copyOfCheckedFilters[i] = !checkedFilters[i];
    setCheckedFilters(copyOfCheckedFilters);
    setTheAllFilter(false);
    let copyOfEventTypeFilter = [...eventTypeFilter];
    if (eventTypeFilter.includes(eventType)) {
      copyOfEventTypeFilter = copyOfEventTypeFilter.filter((type) => type !== eventType);
      setEventTypeFilter(copyOfEventTypeFilter);
      if (copyOfEventTypeFilter.length === 0) setTheAllFilter(!theAllFilter);
    } else {
      copyOfEventTypeFilter.push(eventType);
      setEventTypeFilter(copyOfEventTypeFilter);
    }
  };

  //================================================================================================

  return (
    <div
      className={`table-wrapper ${!globalSearch && !hasExport ? "no-header" : ""}`}
      ref={wrapperRef}
    >
      {/*/!* update the filters when the page is loaded, will need to be updated when a new search preference is saved too *!/*/}
      {/*{updateFilters(filters)}*/}
      <div className="search-wrapper" style={{ display: "flex", flexWrap: "wrap" }}>
        {showSearchPref && (
          <div style={{ paddingTop: "1em" }}>
            {/* dropdown for selecting a search preference */}
            <Dropdown
              panelClassName="table-filter-dropdown"
              value={searchPreference}
              onChange={(e) => {
                let selectedDropdownFilter = e.target.value;
                // to convert the date strings into actual dates to avoid errors when using saved dates as search options
                selectedDropdownFilter = filterToDate(selectedDropdownFilter);
                setSearchPreference(selectedDropdownFilter);
                // this code is just to delete the 'useless' key defined to make the value unique
                // copy is exactly the same with the selectedDropdownFilter
                let copy = { ...selectedDropdownFilter };
                delete copy["useless"];
                setFilters(copy);
                updateFilters(copy);
              }}
              options={preferencesArray}
              placeholder={"Search preferences"}
              style={{ width: "200px" }}
            />
          </div>
        )}
        {showSearchPref && (
          <div
            className={`btn-2 p-mr-3`}
            style={{
              paddingTop: "1em",
              marginLeft: isSMobile ? "0px" : "7px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {/* button for toggling submitting search preference modal */}
            <Button
              label="Save Search Preference"
              onClick={() => setSaveFilterDialog(true)}
              className={isSMobile ? "button" : "button left-margin"}
              style={{ marginBottom: "0.5em" }}
            />
            <Button
              label="Delete Search Preference"
              onClick={() => deletepreference()}
              className={isSMobile ? "button" : "button left-margin"}
              style={{ marginBottom: "0.5em" }}
            />
          </div>
        )}
        {/* dialog for submitting new search preferences */}
        <Dialog
          className="custom-main-dialog configure-checks-dialog"
          header={"Save Custom Search Preference"}
          visible={saveFilterDialog}
          onHide={() => setSaveFilterDialog(false)}
          style={{ width: "60vw" }}
        >
          <CustomInputText
            type="name"
            placeholder="Enter search preference name"
            value={searchPreferenceName}
            onChange={setSearchPreferenceName}
            leftStatus
          />
          <div style={{ paddingTop: "1em" }}>
            <Button
              label="Submit"
              onClick={() => {
                setSaveFilterDialog(false);
                saveFilterOption(searchPreferenceName);
              }}
              className="button"
            />
          </div>
        </Dialog>
        {/* dialog for deleting search perference*/}
        <Dialog
          className="custom-main-dialog configure-checks-dialog"
          header={"Delete Custom Search Preference"}
          visible={deleteFilterDialog}
          onHide={() => setDeleteFilterDialog(false)}
          style={{ width: "60vw" }}
        >
          <p>Please select the search perference you want to delete.</p>
          {filterButtons}
          <div style={{ paddingTop: "1em" }}>
            <Button
              label="Submit"
              onClick={() => {
                setDeleteFilterDialog(false);
                const deletelist = [];
                for (let i = 0; i < filterButtons.length; i++) {
                  if (checkedFilters[i] === true) {
                    deletelist.push(eventTypes[i]);
                    checkedFilters[i] = false;
                  }
                }
                deleteFilterOption(deletelist);
              }}
              className="button"
            />
            <Button
              label="Cancel"
              onClick={() => {
                setDeleteFilterDialog(false);
                for (let i = 0; i < filterButtons.length; i++) {
                  checkedFilters[i] = false;
                }
              }}
              className="button left-margin"
            />
          </div>
        </Dialog>
      </div>
      {dataReady || (!dataReady && !isMobile) ? (
        <DataTable
          first={first}
          onPage={onPageHandler}
          onValueChange={handleValueChange}
          ref={dtRef}
          header={tableToolsHeader}
          globalFilter={globalFilter}
          value={list}
          selection={selectedRowData}
          selectionMode={selectionMode}
          dataKey="id"
          metaKeySelection={false}
          loading={!dataReady}
          rowHover
          paginator
          rows={rows ? rows : isMobile ? 5 : 8}
          pageLinkSize={isMobile ? 3 : 5}
          rowClassName={(data) => {
            return { [selectionMode]: true };
          }}
          onSelectionChange={onSelectHandle}
          emptyMessage={t("general.table_no_data_match")}
          resizableColumns
          columnResizeMode="fit"
        >
          {selectionMode ? (
            <Column selectionMode={selectionMode} headerStyle={{ width: "3rem" }} />
          ) : null}
          {dynamicColumns}
          {isMobile && !disableMobileDetail && (
            <Column
              className="detailview-indicator"
              body={() => {
                return (
                  <Button
                    label={t("general.mobile_table_detail_indicator")}
                    icon="pi pi-chevron-right"
                    className="detailview-button"
                    iconPos="right"
                  />
                );
              }}
            />
          )}
        </DataTable>
      ) : (
        <LoadingAnimation />
      )}
    </div>
  );
};

export default Table;
