import React from "react";
import ChecklistItem from "./ChecklistItem";
import CardWidget from "./CardWidget";
import "../../styles/OperatorsPanel/OpCheckForm.scss";

const GeneralRadioForm = ({ header = "", items = [] }) => {
  // items should be an array of objects,
  // sample item:
  // let obj = {
  //     value: value,
  //     setValue: setValue,
  //     name: name,
  //     labels: [...labels],
  //     isTextFiled: boolean
  // }
  return (
    <React.Fragment>
      <h4 className="p-mb-3">{header}</h4>
      {items.map((ele, index) => {
        if (ele?.isTextField) {
          return (
            <CardWidget status={ele.value ? true : false} key={ele.name + index}>
              <label className="h5 font-weight-bold">{ele.labels[0]}</label>
              <span className="p-input-icon-right w-100">
                <input
                  className={"p-inputtext p-component w-100"}
                  value={ele.value}
                  placeholder={ele.value}
                  onChange={function (e) {
                    ele.setValue(e.target.value);
                  }}
                  keyfilter={/^\d*\.?\d*$/}
                />
              </span>
            </CardWidget>
          );
        }
        return (
          <ChecklistItem
            value={ele.value}
            onChange={ele.setValue}
            name={ele.name}
            key={ele.name + index}
            // labels={[t("lookupAnnualReportsPanelIndex.lifejackets_inspected")]}
            labels={ele.labels}
            status={ele.value || ele.value === false}
          />
        );
      })}
    </React.Fragment>
  );
};

export default GeneralRadioForm;
