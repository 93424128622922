import React, { useState, useEffect } from "react";
import robotStep1 from "../../images/robots/stepper/robot_step_1.png";
import robotStep2 from "../../images/robots/stepper/robot_step_2.png";
import robotStep3 from "../../images/robots/stepper/robot_step_3.png";
import robotStep4 from "../../images/robots/stepper/robot_step_4.png";
import robotStep5 from "../../images/robots/stepper/robot_step_5.png";
import robotStep6 from "../../images/robots/stepper/robot_step_6.png";
import robotStep7 from "../../images/robots/stepper/robot_step_7.png";
import robotStep8 from "../../images/robots/stepper/robot_step_8.png";
import robotStep9 from "../../images/robots/stepper/robot_step_9.png";
import "../../styles/ShareComponents/RequestProgress.scss";

const RequestProgress = ({ steps, contents, activeStep, layout = "horizontal" }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const stepImages = [
    robotStep1,
    robotStep2,
    robotStep3,
    robotStep4,
    robotStep5,
    robotStep6,
    robotStep7,
    robotStep8,
    robotStep9,
  ];

  useEffect(() => {
    let index = steps.findIndex((step) => step.toLowerCase() === activeStep.toLowerCase());
    // console.log(index);
    setActiveIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <React.Fragment>
      <div
        className={`request-progress request-progress-${layout}
      ${activeIndex === -1 ? "request-progress-status-not-displayed" : ""}`}
      >
        {steps.map((step, index) => (
          <div
            key={index}
            className={`
              progress-event
              ${activeIndex === index ? "connector-extend" : ""}
            `}
          >
            {activeIndex === index && (
              <div className="progress-event-robot">
                <img src={stepImages[index]} alt="step-img" />
              </div>
            )}
            <div
              className={`
              progress-event-content
              ${activeIndex === index ? "content-active" : ""}`}
            >
              <div className="title">{step}</div>
              {activeIndex === index && <div className="details">{contents[index]}</div>}
            </div>
            <div className="progress-event-separator">
              <div
                className={`
                marker
                ${activeIndex > index ? "marker-active" : ""}
                ${activeIndex === index ? "marker-grow" : ""}`}
              />
              {index + 1 < steps.length && (
                <div
                  className={`
                  connector
                  ${activeIndex > index ? "connector-active" : ""}`}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default RequestProgress;
