import React, { useEffect } from "react";
import ReactGA from "react-ga";
import "./custom.scss";
import { getAuthHeader } from "./helpers/Authorization";
import Spinner from "./components/ShareComponents/Spinner";
import { Suspense } from "react";
import PrimeInterface from "./routes/PrimeInterface";
import NoAuthRoute from "./routes/NoAuthRoute";
import smoothscroll from "smoothscroll-polyfill";
import { useSelector } from "react-redux";

// kick off the smooth scroll polyfill!
smoothscroll.polyfill();

// Google Analytics setup
const GOOGLE_ANALYTICS_TRACKING_ID = "UA-192617522-1";
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

const App = (props) => {
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      // debugger;
      if (child.parentNode !== this) {
        if (console) {
          console.error("Cannot remove a child from a different parent", child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    //to clear query params on page refresh
    window.history.replaceState(null, "", window.location.pathname);
  }, []);

  const { platform } = useSelector((state) => state.apiCallData);
  let authHeader = getAuthHeader(() => platform);

  let screen = <NoAuthRoute />;
  if (authHeader !== null) {
    screen = <PrimeInterface />;
  }
  return <Suspense fallback={<Spinner />}>{screen}</Suspense>;
};

export default App;
