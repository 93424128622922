/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { getAuthHeader, getRolePermissions } from "../../../helpers/Authorization";
import * as Constants from "../../../constants";
import { CTRL_AUDIO_PLAY } from "../../../redux/types/audioTypes";
import PanelHeader from "../../ShareComponents/helpers/PanelHeader";
import QuickAccessTabs from "../../ShareComponents/QuickAccessTabs";
import VINSearch from "../../ShareComponents/helpers/VINSearch";
import WarningMsg from "../../ShareComponents/WarningMsg";
import { errorAlert, successAlert, loadingAlert } from "../../ShareComponents/CommonAlert";
import AnReportForm from "./AnReportForm";
import ConfigureDialog from "./ConfigureDialog";
import ConsoleHelper from "../../../helpers/ConsoleHelper";
import { Button } from "primereact/button";
import "../../../styles/OperatorsPanel/DailyOperatorsCheck.scss";

// BACKEND DATA
// class DailyInspection(models.Model):
//     vin = models.CharField(max_length=17)
//     inspection_date = models.DateField()

//     # Lifesaving Equipment
//     pfd_lifejacket_ledger = models.TextField(blank=True, null=True)
//     throw_bags = models.BooleanField(default=False)
//     heaving_line = models.BooleanField(default=False)
//     liferings = models.BooleanField(default=False)
//     reboarding_device = models.BooleanField(default=False)
//     watertight_flashlight = models.BooleanField(default=False)
//     pyrotechnic_flares = models.BooleanField(default=False)
//     first_aid_kit = models.BooleanField(default=False)

//     # Engine and all Mechanical
//     engine_working = models.BooleanField(default=False)

//     # Communications
//     marine_vhf_radio = models.BooleanField(default=False)
//     police_radio = models.BooleanField(default=False)
//     epirb_registered = models.BooleanField(default=False)

//     # Bilge Pump and Bilge Alarms
//     bilge_pump_clear = models.BooleanField(default=False)

//     # Steering Controls and/or Hydraulics
//     steering_controls_operable = models.BooleanField(default=False)

//     # All Hatches, Vents and Openings Operable
//     hatches_operable = models.BooleanField(default=False)

//     # Emergency Drills
//     training_skills_exercised = models.BooleanField(default=False)
//     drills_entered_logbook = models.BooleanField(default=False)
//     emergency_briefings_conducted = models.BooleanField(default=False)

//     # Fire Extinguishers and Suppression Systems
//     fire_extinguishers_inspected = models.BooleanField(default=False)

//     # Hull and Structure
//     regular_inspections_completed = models.BooleanField(default=False)

//     # Electrical
//     electrical_inspections_completed = models.BooleanField(default=False)

//     # Navigation and Strobe Lights
//     broken_cracked_lenses = models.BooleanField(default=False)
//     lights_functioning = models.BooleanField(default=False)

//     # Fuel Systems
//     fuel_connections_good = models.BooleanField(default=False)
//     fuel_containers_stored_properly = models.BooleanField(default=False)

//     # Anchoring System
//     ground_tackle_inspected = models.BooleanField(default=False)
//     bitter_end_attached = models.BooleanField(default=False)

//     # Guard, Grab Rails and/or Grab Lines
//     welds_bolts_screws_good = models.BooleanField(default=False)
//     grab_lines_good = models.BooleanField(default=False)

//     # Crew Qualifications
//     operators_trained = models.BooleanField(default=False)
//     crew_equipment_trained = models.BooleanField(default=False)

//     # Additional Fields
//     vessel_damaged_since_last_report = models.BooleanField(default=False)
//     structural_changes_made_since_last_report = models.BooleanField(default=False)

// const response = {
//   inspection_date: ["This field may not be null."],
//   throw_bags: ["This field may not be null."],
//   heaving_line: ["This field may not be null."],
//   liferings: ["This field may not be null."],
//   reboarding_device: ["This field may not be null."],
//   watertight_flashlight: ["This field may not be null."],
//   pyrotechnic_flares: ["This field may not be null."],
//   first_aid_kit: ["This field may not be null."],
//   engine_working: ["This field may not be null."],
//   marine_vhf_radio: ["This field may not be null."],
//   police_radio: ["This field may not be null."],
//   epirb_registered: ["This field may not be null."],
//   bilge_pump_clear: ["This field may not be null."],
//   steering_controls_operable: ["This field may not be null."],
//   hatches_operable: ["This field may not be null."],
//   training_skills_exercised: ["This field may not be null."],
//   drills_entered_logbook: ["This field may not be null."],
//   emergency_briefings_conducted: ["This field may not be null."],
//   fire_extinguishers_inspected: ["This field may not be null."],
//   regular_inspections_completed: ["This field may not be null."],
//   electrical_inspections_completed: ["This field may not be null."],
//   broken_cracked_lenses: ["This field may not be null."],
//   lights_functioning: ["This field may not be null."],
//   fuel_connections_good: ["This field may not be null."],
//   fuel_containers_stored_properly: ["This field may not be null."],
//   ground_tackle_inspected: ["This field may not be null."],
//   bitter_end_attached: ["This field may not be null."],
//   anchor_secured: ["This field may not be null."],
//   welds_bolts_screws_good: ["This field may not be null."],
//   grab_lines_good: ["This field may not be null."],
//   operators_trained: ["This field may not be null."],
//   vessel_damaged_since_last_report: ["This field may not be null."],
//   structural_changes_made_since_last_report: ["This field may not be null."],
// };

const AnnualReport = () => {
  const { t } = useTranslation();
  const [vehicle, setVehicle] = useState(null);
  const [checkItems, setCheckItems] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const [checksDialog, setchecksDialog] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(Date.now());
  const [exportPdfFunction, setExportPdfFunction] = useState(null);
  const [setAllFunction, setSetAllFunction] = useState(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${Constants.MOBILE_BREAKPOINT}px)` });

  useEffect(() => {
    setDataReady(false);
    setCheckItems(null);
    if (vehicle) {
      const cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${Constants.ENDPOINT_PREFIX}/api/v1/Assets/Get/Checks/${vehicle.VIN}`, {
          ...getAuthHeader(),
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          const checks = {
            //Lifesaving Equipment
            pfd_lifejacket_ledger: "Enter Ledger",
            throw_bags: true,
            heaving_line: true,
            liferings: true,
            reboarding_device: true,
            watertight_flashlight: true,
            pyrotechnic_flares: true,
            first_aid_kit: true,

            // Engine and all Mechanical
            engine_working: true,

            // Communications
            marine_vhf_radio: true,
            police_radio: true,
            epirb_registered: true,

            // Bilge Pump and Bilge Alarms
            bilge_pump_clear: true,

            // Steering Controls and/or Hydraulics
            steering_controls_operable: true,

            // All Hatches, Vents and Openings Operable
            hatches_operable: true,

            // Emergency Drills
            training_skills_exercised: true,
            drills_entered_logbook: true,
            emergency_briefings_conducted: true,

            // Fire Extinguishers and Suppression Systems
            fire_extinguishers_inspected: true,

            // Hull and Structure
            regular_inspections_completed: true,

            // Electrical
            electrical_inspections_completed: true,

            // Navigation and Strobe Lights
            broken_cracked_lenses: true,
            lights_functioning: true,

            // Fuel Systems
            fuel_connections_good: true,
            fuel_containers_stored_properly: true,

            // Anchoring System
            ground_tackle_inspected: true,
            bitter_end_attached: true,
            anchor_secured: true,

            // Guard, Grab Rails and/or Grab Lines
            welds_bolts_screws_good: true,
            grab_lines_good: true,

            // Crew Qualifications
            operators_trained: true,
            crew_equipment_trained: true,
            vessel_damaged_since_last_report: true,
            structural_changes_made_since_last_report: true,
          };
          // console.log(response.data);
          //   let checks = response.data;
          //   delete checks["id"];
          //   delete checks["asset_type_name"];
          //   delete checks["date_created"];
          //   delete checks["date_updated"];
          //   delete checks["created_by"];
          //   delete checks["modified_by"];
          setDataReady(true);
          setCheckItems(checks);
        })
        .catch((error) => {
          setDataReady(false);
          errorAlert(error.customErrorMsg);
        });
      return () => {
        //Doing clean up work, cancel the asynchronous api call
        cancelTokenSource.cancel("cancel the asynchronous api call");
      };
    }
  }, [vehicle]);

  return (
    <div>
      {isMobile && (
        <QuickAccessTabs
          tabs={["Annual Report", "Lookup"]}
          activeTab={"Annual Report"}
          urls={["/operators/annual-report", "/operators/lookup-annual-report"]}
        />
      )}
      <PanelHeader
        icon={faCalendarCheck}
        text={t("lookupAnnualReportsPanelIndex.annual_report")}
        mobileBg
      />
      {!isMobile && (
        <QuickAccessTabs
          tabs={["Annual Report", "Lookup"]}
          activeTab={"Annual Report"}
          urls={["/operators/annual-report", "/operators/lookup-annual-report"]}
        />
      )}
      <React.Fragment>
        <div
          className={`VIN-cont ${isMobile ? "p-pb-4" : "p-mt-5"} flex-wrap search-configure-check`}
        >
          <div className="search-container">
            <VINSearch
              key={forceUpdate}
              onVehicleSelected={(v) => {
                if (!!v) setVehicle(v);
              }}
            />
          </div>
          {/* {getRolePermissions().role.toLowerCase() !== "operator" && (
            <div className={`configure-container ml-auto ${isMobile ? "p-mr-2" : "p-mr-4"}`}>
              <Button
                className="p-button-rounded"
                label={!isMobile ? "Configure Reports" : ""}
                icon="pi pi-sliders-v"
                onClick={() => setchecksDialog(true)}
              />
            </div>
          )} */}
        </div>
        <ConfigureDialog checksDialog={checksDialog} setchecksDialog={setchecksDialog} />
        {vehicle &&
          !Array.isArray(vehicle) &&
          dataReady &&
          (vehicle.status.toLowerCase() !== "inoperative" ? (
            <div>
              <AnnualReportCheckForm
                checkItems={checkItems}
                vehicle={vehicle}
                setForceUpdate={setForceUpdate}
                resetForm={setVehicle}
                exportPdfFunction={exportPdfFunction}
                setExportPdfFunction={setExportPdfFunction}
                setAllFunction={setAllFunction}
                setSetAllFunction={setSetAllFunction}
                selectedTitle={`${t("lookupAnnualReportsPanelIndex.create_annual_report_for_vin", {
                  vehicleVIN: vehicle.VIN,
                })}`}
              />
            </div>
          ) : (
            <div className={`${isMobile ? "p-mx-3 p-mt-3" : ""}`}>
              <WarningMsg message={t("lookupAnnualReportsPanelIndex.inoperative_warning_msg")} />
            </div>
          ))}
      </React.Fragment>
    </div>
  );
};

const AnnualReportCheckForm = ({
  checkItems,
  vehicle,
  resetForm,
  selectedTitle,
  setForceUpdate,
  exportPdfFunction,
  setExportPdfFunction,
  setAllFunction,
  setSetAllFunction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pfdLifejacketLedger, setPfdLifejacketLedger] = useState("");
  const [mileage, setMileage] = useState(null);
  const [hours, setHours] = useState(null);
  const [fuelLevel, setFuelLevel] = useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const [validationWarningMsg, setValidationWarningMsg] = useState(null);
  const [validationSearch, setValidationSearch] = useState(false);
  const [checklistStatus, setChecklistStatus] = useState(false);
  const [checkFormItems, setCheckFormItems] = useState({});
  const [checkFormComments, setCheckFormComments] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);

  //   const subCheckTitles = ["lights", "fluids", "leaks", "safety_equipment"];

  useEffect(() => {
    setValidationWarningMsg(null);
    setCheckFormItems({ pfd_lifejacket_ledger: "Enter Ledger" });
    setCheckFormComments({});
    setChecklistStatus(false);
  }, [vehicle]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("Submit");
    setSubmitClicked(true);
    dispatch({ type: CTRL_AUDIO_PLAY, payload: "submit" });
    // event.preventDefault();
    // let commentArray = Object.keys(checkFormComments).map((name, i) => {
    //   return { comment: checkFormComments[name], check: name };
    // });
    // let operatorCheck = {
    //   // Add annual report data here for posting

    //   ...checkFormItems,
    //   VIN: vehicle.VIN,
    //   inspection_date: new Date().toISOString().slice(0, 10),
    // };
    // console.log("oeprator check", operatorCheck);
    // console.log("Errors: " + errors);
    errors && setSubmitClicked(true);
    // : sendOperatorCheck(operatorCheck);
    setForceUpdate(Date.now());
  };

  const errors = (function () {
    // console.log("error function called");
    for (let item in checkItems) {
      if (item === "pfd_lifejacket_ledger") {
        if (checkFormItems[item]) {
          continue;
        }
      }
      if (!checkFormItems[item] && checkFormItems[item] !== false) {
        // console.log(item + " missing");
        return true;
      }
    }
    return false;
  })();

  const sendOperatorCheck = (operatorCheck) => {
    // console.log("operator check", operatorCheck);
    loadingAlert();
    axios
      .post(
        `${Constants.ENDPOINT_PREFIX}/api/v1/AnnualReport/Add/VIN/${vehicle.VIN}`,
        operatorCheck,
        getAuthHeader()
      )
      .then((response) => {
        successAlert(
          "msg",
          t("lookupAnnualReportsPanelIndex.submit_check_success"),
          true,
          "Export PDF",
          exportPdfFunction
        );
        resetForm(null);
        dispatch({ type: CTRL_AUDIO_PLAY, payload: "success_alert" });
      })
      .catch((error) => {
        errorAlert(error.customErrorMsg, operatorCheck);
        dispatch({ type: CTRL_AUDIO_PLAY, payload: "error_alert" });
        ConsoleHelper(error);
      });
  };

  // useEffect(() => {
  //   if (mileage || hours) {
  //     if (vehicle.hours_or_mileage.toLowerCase() === "both" && !hours) {
  //       setValidationWarningMsg("Please provide hours for auto-validation.");
  //     } else if (vehicle.hours_or_mileage.toLowerCase() === "both" && !mileage) {
  //       setValidationWarningMsg("Please provide mileage for auto-validation.");
  //     } else {
  //       const delayDebounceFn = setTimeout(() => {
  //         setValidationSearch(true);
  //         setValidationWarningMsg(null);
  //         axios
  //           .post(
  //             `${Constants.ENDPOINT_PREFIX}/api/v1/Assets/Validate/Usage`,
  //             {
  //               VIN: vehicle.VIN,
  //               mileage: parseFloat(mileage),
  //               hours: parseFloat(hours),
  //             },
  //             getAuthHeader()
  //           )
  //           .then((response) => {
  //             setValidationSearch(false);
  //           })
  //           .catch((error) => {
  //             setValidationWarningMsg(error.customErrorMsg);
  //             setValidationSearch(false);
  //             ConsoleHelper(error);
  //           });
  //       }, 1000);
  //       return () => clearTimeout(delayDebounceFn);
  //     }
  //   }
  // }, [mileage, hours]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    for (var key in checkItems) {
      if (key === "pfd_lifejacket_ledger") {
        if (checkFormItems[key] === "") {
          setDisableButton(true);
          setChecklistStatus(false);
          break;
        } else {
          setChecklistStatus(true);
          setDisableButton(false);
        }
      } else if (
        checkItems[key] &&
        // key !== "fuel" &&
        // !(subCheckTitles.indexOf(key) > -1) &&
        checkFormItems[key] !== true &&
        checkFormItems[key] !== false
      ) {
        setDisableButton(true);
        setChecklistStatus(false);
        break;
      } else {
        setChecklistStatus(true);
        setDisableButton(false);
        // if (vehicle.hours_or_mileage.toLowerCase() === "both" && (!mileage || !hours)) {
        //   setDisableButton(true);
        // } else if (vehicle.hours_or_mileage.toLowerCase() === "hours" && !hours) {
        //   setDisableButton(true);
        // } else if (vehicle.hours_or_mileage.toLowerCase() === "mileage" && !mileage) {
        //   setDisableButton(true);
        // } else {
        //   setDisableButton(false);
        // }
      }
    }
  }, [vehicle.hours_or_mileage, mileage, hours, checkFormItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorAlert = (errorMsg, data) => {
    return swal({
      title: t("general.error"),
      text: errorMsg,
      icon: "error",
      buttons: { resend: t("general.try_again"), cancel: t("general.cancel") },
    }).then((value) => {
      if (value === "resend") sendOperatorCheck(data);
    });
  };

  if (!vehicle) return null;

  return (
    <div className="report-form-container p-mx-3">
      {/* Testing Only */}
      {/* <div className="p-ml-3 table-export-btn" style={{ textAlign: "right" }}>
        <Button type="button" icon="pi pi-external-link" label="SetAll" onClick={setAllFunction} />
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export PDF"
          onClick={exportPdfFunction}
        />
      </div> */}

      <form onSubmit={handleSubmit}>
        <AnReportForm
          selectedTitle={selectedTitle}
          vehicle={vehicle}
          disableButton={disableButton}
          validationSearch={validationSearch}
          validationWarningMsg={validationWarningMsg}
          checklistStatus={checklistStatus}
          checkFormItems={checkFormItems}
          setCheckFormItems={setCheckFormItems}
          checkFormComments={checkFormComments}
          setCheckFormComments={setCheckFormComments}
          submitClicked={submitClicked}
          setSubmitClicked={setSubmitClicked}
          checkItems={checkItems}
          exportPdfFunction={exportPdfFunction}
          setExportPdfFunction={setExportPdfFunction}
          setAllFunction={setAllFunction}
          setSetAllFunction={setSetAllFunction}
          resetForm={resetForm}
        />
      </form>
    </div>
  );
};

export default AnnualReport;
