import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { fleetGuruContents } from "./FleetGuruContents";
import robotOn from "../../../images/menu/topbar_menu_robot_on.png";
import "../../../styles/helpers/button4.scss";
import ReactPlayer from "react-player";
import dashboard from "../../../images/menu/icon_menu_dashboard.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

const FleetGuru = ({ setOnFleetGuru, setAssistantStatus }) => {
  const { t } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState("");
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [searchField, setSearchField] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const [showSteps, setShowSteps] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);

  useEffect(() => {
    if (searchField) {
      setSelectedTopic("");
      const filtered = fleetGuruContents.filter((content) => {
        return t(content.title).toLowerCase().includes(searchField.toLowerCase());
      });
      setFilteredTopics(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const handleBackClick = () => {
    setShowSteps(false);
    setSelectedTopic("");
  };

  const handleStepTitleClick = (title) => {
    setShowSteps(!showSteps);
    setSelectedTitle(title);
  };

  // Default Content Component
  const DefaultContent = ({
    topics,
    handleTopicClick,
    isMobile,
    setOnFleetGuru,
    setAssistantStatus,
    t,
  }) => {
    return (
      <div className="contents">
        <div className="header">{t("fleetGuru.default_header")}</div>
        {topics.map((topic, index) => (
          <TopicTitle
            key={index}
            topic={topic}
            handleTopicClick={handleTopicClick}
            t={t}
            index={index}
          />
        ))}
        {!isMobile && (
          <AssistantBackButton
            setOnFleetGuru={setOnFleetGuru}
            setAssistantStatus={setAssistantStatus}
            t={t}
          />
        )}
      </div>
    );
  };

  // Filtered Topics Component
  const FilteredTopics = ({ filteredTopics, handleTopicClick, t }) => {
    if (filteredTopics.length === 0) {
      return (
        <div className="contents">
          <div className="header text-center">{t("fleetGuru.no_topics_found")}</div>
        </div>
      );
    } else {
      return (
        <div className="contents">
          <div className="header">{t("fleetGuru.related_header")}</div>
          {filteredTopics.map((topic, index) => (
            <TopicTitle key={index} topic={topic} handleTopicClick={handleTopicClick} t={t} />
          ))}
        </div>
      );
    }
  };

  // Selected Topic Component
  const SelectedTopic = ({
    selectedTopic,
    showSteps,
    setSelectedTitle,
    handleBackClick,
    handleStepTitleClick,
    t,
  }) => {
    return (
      <div className="topic-details">
        <div className="no-style-btn p-my-3">
          <Button
            label={t("general.back")}
            className="p-button-link"
            icon="pi pi-chevron-left"
            onClick={handleBackClick}
          />
        </div>
        <div className="artical">
          {selectedTopic.content.map((item, index) => (
            <div key={index}>
              <TopicSubTitle
                item={item}
                showSteps={showSteps}
                setSelectedTitle={setSelectedTitle}
                handleStepTitleClick={handleStepTitleClick}
                t={t}
                index={index}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Topic Title Component
  const TopicTitle = ({ topic, handleTopicClick, t, index }) => {
    return (
      <React.Fragment>
        {index !== 0 && <hr />}
        <div className="topic-title" id={topic.scope} onClick={() => handleTopicClick(topic)}>
          {topic?.scope === "guruGeneral" ? (
            <FontAwesomeIcon
              alt="Topic Icon"
              style={{ maxHeight: 20, width: 25 }}
              icon={faBookOpen}
            />
          ) : (
            <img src={topic.icon} style={{ maxHeight: 20, width: 25 }} alt="Topic Icon" />
          )}
          <span>{t(topic.title)}</span>
          <i className="pi pi-chevron-right" />
        </div>
      </React.Fragment>
    );
  };

  // Assistant Back Button Component
  const AssistantBackButton = ({ setOnFleetGuru, setAssistantStatus, t }) => {
    return (
      <Button
        className="assistant-back-btn"
        onClick={() => {
          setOnFleetGuru(false);
          setAssistantStatus(true);
        }}
      >
        <span>{t("fleetGuru.show_me_around")}</span>
        <div className="assistant-back-img">
          <img src={robotOn} alt="" />
        </div>
      </Button>
    );
  };

  // Topic Sub Title Component
  const TopicSubTitle = ({ item, showSteps, setSelectedTitle, handleStepTitleClick, t, index }) => {
    return (
      <div>
        <div className="topic-sub-title" onClick={() => handleStepTitleClick(item.title)}>
          {index !== 0 && <hr />}
          <div className="sub-title-wrapper">
            {t(item.title)}
            <i className="pi pi-chevron-right" style={{ fontSize: "16px", marginLeft: "auto" }} />
          </div>
          {/*{index!==selectedTopic.content.length-1&& <hr />}*/}
        </div>
        {showSteps && item.title === selectedTitle && (
          <div>
            <div className="steps-ordered-list">
              {t(item.steps).map((step, stepIndex) => (
                <p className="step-list" key={stepIndex}>
                  {t(step)}
                </p>
              ))}
            </div>
            {/*{item.video !== "" && (*/}
            {/*  <div style={{ paddingTop: "20px" }}>*/}
            {/*    <video controls width="450px">*/}
            {/*      <source src={item.video} type="video/mp4" style={{ padding: "1rem" }} />*/}
            {/*    </video>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fleet-guru-container">
      {/* Header and Search Bar */}
      <div className="header-search">
        <div className="greeting-msg">{t("fleetGuru.greeting_msg")}</div>
        <div className="search-bar">
          <div className="p-inputgroup">
            <Button icon="pi pi-search" />
            <InputText
              placeholder={t("fleetGuru.searchbar_placeholder")}
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Content Field */}
      <div className="content-field">
        {/* Default Content */}
        {!searchField && !selectedTopic && (
          <DefaultContent
            topics={fleetGuruContents}
            handleTopicClick={handleTopicClick}
            isMobile={isMobile}
            setOnFleetGuru={setOnFleetGuru}
            setAssistantStatus={setAssistantStatus}
            t={t}
          />
        )}

        {/* Filtered Topics */}
        {searchField && !selectedTopic && (
          <FilteredTopics
            filteredTopics={filteredTopics}
            handleTopicClick={handleTopicClick}
            t={t}
          />
        )}

        {/* Selected Topic Details */}
        {selectedTopic && (
          <SelectedTopic
            selectedTopic={selectedTopic}
            showSteps={showSteps}
            setSelectedTitle={setSelectedTitle}
            handleBackClick={handleBackClick}
            handleStepTitleClick={handleStepTitleClick}
            t={t}
          />
        )}
      </div>
    </div>
  );
};

export default FleetGuru;
