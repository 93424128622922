import dashboard from "../../../images/menu/icon_menu_dashboard.png";
import transfers from "../../../images/menu/icon_menu_transfers.png";
import repairs from "../../../images/menu/icon_menu_repairs.png";
import maintenance from "../../../images/menu/icon_menu_maintenance.png";
import incidents from "../../../images/menu/icon_menu_incidents.png";
import issues from "../../../images/menu/icon_menu_issues.png";
import operators from "../../../images/menu/icon_menu_operators.png";
import disposal from "../../../images/menu/icon_menu_disposal.png";
import assets from "../../../images/menu/icon_menu_assets.png";
import energy from "../../../images/menu/icon_menu_energy.png";

export const fleetGuruContents = [


    {
        scope: "guruGeneral",
        icon: "",
        title: "guruGeneral.title",
        content: [

            {
                title: "guruGeneral.fleet_guru.title",
                description: "guruGeneral.fleet_guru.description",
                steps: "guruGeneral.fleet_guru.steps",
                video: "",
            },
            {
                title: "guruGeneral.global_search.title",
                description: "guruGeneral.global_search.description",
                steps: "guruGeneral.global_search.steps",
                video: "",
            },
            {
                title: "guruGeneral.vessel_details_page.title",
                description: "guruGeneral.vessel_details_page.description",
                steps: "guruGeneral.vessel_details_page.steps",
                video: "",
            },
            {
                title: "guruGeneral.user_profile/change_password.title",
                description: "guruGeneral.user_profile/change_password.description",
                steps: "guruGeneral.user_profile/change_password.steps",
                video: "",
            },
            {
                title: "guruGeneral.manage_user_accounts.title",
                description: "guruGeneral.manage_user_accounts.description",
                steps: "guruGeneral.manage_user_accounts.steps",
                video: "",
            },
            {
                title: "guruGeneral.feedback_report_a_problem.title",
                description: "guruGeneral.feedback_report_a_problem.description",
                steps: "guruGeneral.feedback_report_a_problem.steps",
                video: "",
            },
            {
                title: "guruGeneral.approval_requests/approving_or_rejecting_a_request.title",
                description: "guruGeneral.approval_requests/approving_or_rejecting_a_request.description",
                steps: "guruGeneral.approval_requests/approving_or_rejecting_a_request.steps",
                video: "",
            },
            {
                title: "guruGeneral.approval_requests_request_status.title",
                description: "guruGeneral.approval_requests_request_status.description",
                steps: "guruGeneral.approval_requests_request_status.steps",
                video: "",
            },
        ],
    },
    {

        scope: "guruDashboard",
        icon: dashboard,
        title: "guruDashboard.title",
        content: [
            {
                title: "guruDashboard.fleet_at_a_glance.title",
                description: "guruDashboard.fleet_at_a_glance.description",
                steps: "guruDashboard.fleet_at_a_glance.steps",
                video: "",
            }
        ]

    },
    {
        scope: "guruVessels",
        icon: assets,
        title: "guruVessels.title",
        content: [
            {
                title: "guruVessels.vessel_overview.title",
                description: "guruVessels.vessel_overview.description",
                steps: "guruVessels.vessel_overview.steps",
                video: "",
            },
            {
                title: "guruVessels.vessel_request.title",
                description: "guruVessels.vessel_request.description",
                steps: "guruVessels.vessel_request.steps",
                video: "",
            },
            {
                title: "guruVessels.current_orders.title",
                description: "guruVessels.current_orders.description",
                steps: "guruVessels.current_orders.steps",
                video: "",
            },
        ],
    },
    {
        scope: "guruTransfers",
        icon: transfers,
        title: "guruTransfers.title",
        content: [
            {
                title: "guruTransfers.vessel_transfer_map.title",
                description: "guruTransfers.vessel_transfer_map.description",
                steps: "guruTransfers.vessel_transfer_map.steps",
                video: "",
            },
            {
                title: "guruTransfers.list_transfers.title",
                description: "guruTransfers.list_transfers.description",
                steps: "guruTransfers.list_transfers.steps",
                video: "",
            },
            {
                title: "guruTransfers.transfer_vessel.title",
                description: "guruTransfers.transfer_vessel.description",
                steps: "guruTransfers.transfer_vessel.steps",
                video: "",
            },

        ],
    },
    {
        scope: "guruRepairs",
        icon: repairs,
        title: "guruRepairs.title",
        content: [
            {
                title: "guruRepairs.list_repairs.title",
                description: "guruRepairs.list_repairs.description",
                steps: "guruRepairs.list_repairs.steps",
                video: ""
            },
            {
                title: "guruRepairs.repairs_details.title",
                description: "guruRepairs.repairs_details.description",
                steps: "guruRepairs.repairs_details.steps",
                video: ""
            },
            {
                title: "guruRepairs.parts_labor.title",
                description: "guruRepairs.parts_labor.description",
                steps: "guruRepairs.parts_labor.steps",
                video: ""
            },
            {
                title: "guruRepairs.repair_request.title",
                description: "guruRepairs.repair_request.description",
                steps: "guruRepairs.repair_request.steps",
                video: ""
            },

        ],
    },
    {
        scope: "guruMaintenance",
        icon: maintenance,
        title: "guruMaintenance.title",
        content: [

            {
                title: "guruMaintenance.maintenance_status.title",
                description: "guruMaintenance.maintenance_status.description",
                steps: "guruMaintenance.maintenance_status.steps",
                video: ""
            },
            {
                title: "guruMaintenance.maintenance_view_details.title",
                description: "guruMaintenance.maintenance_view_details.description",
                steps: "guruMaintenance.maintenance_view_details.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Maintenance%20Details.mp4"
            },
            {
                title: "guruMaintenance.maintenance_update_details.title",
                description: "guruMaintenance.maintenance_update_details.description",
                steps: "guruMaintenance.maintenance_update_details.steps",
                video: ""
            },
            {
                title: "guruMaintenance.maintenance_parts_labor.title",
                description: "guruMaintenance.maintenance_parts_labor.description",
                steps: "guruMaintenance.maintenance_parts_labor.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Maintenance%20Parts%20and%20Costs.mp4"
            },
            {
                title: "guruMaintenance.schedule_maintenance.title",
                description: "guruMaintenance.schedule_maintenance.description",
                steps: "guruMaintenance.schedule_maintenance.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Schedule%20Maintenance.mp4"
            },
            {
                title: "guruMaintenance.maintenance_forecast.title",
                description: "guruMaintenance.maintenance_forecast.description",
                steps: "guruMaintenance.maintenance_forecast.steps",
                video: ""
            },
            {
                title: "guruMaintenance.forecasted_maintenance.title",
                description: "guruMaintenance.forecasted_maintenance.description",
                steps: "guruMaintenance.forecasted_maintenance.steps",
                video: ""
            },
            {
                title: "guruMaintenance.maintenance_lookup.title",
                description: "guruMaintenance.maintenance_lookup.description",
                steps: "guruMaintenance.maintenance_lookup.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Maintenance%20Lookup.mp4"
            },


        ]
    },
    {
        scope: "guruIssues",
        icon: issues,
        title: "guruIssues.title",
        content: [
            {
                title: "guruIssues.unresolved_issues_and_resolved_issues.title",
                description: "guruIssues.unresolved_issues_and_resolved_issues.description",
                steps: "guruIssues.unresolved_issues_and_resolved_issues.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Unresolved%20Issues.mp4",
            },
            {
                title: "guruIssues.search_issues.title",
                description: "guruIssues.search_issues.description",
                steps: "guruIssues.search_issues.steps",
                video: "",
            },
            {
                title: "guruIssues.update_issue_details.title",
                description: "guruIssues.update_issue_details.description",
                steps: "guruIssues.update_issue_details.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Issue%20Details.mp4",
            },
            {
                title: "guruIssues.report_new_issue(Result from an Incident).title",
                description: "guruIssues.report_new_issue(Result from an Incident).description",
                steps: "guruIssues.report_new_issue(Result from an Incident).steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Report%20New%20Vessel%20Issue%20(from%20incident).mp4",
            },
            {
                title: "guruIssues.report_new_issue(Result not from an Incident).title",
                description: "guruIssues.report_new_issue(Result not from an Incident).description",
                steps: "guruIssues.report_new_issue(Result not from an Incident).steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Report%20New%20Vessel%20Issue%20(not%20from%20incident).mp4",
            },
        ],
    },
    {
        scope: "guruIncidents",
        icon: incidents,
        title: "guruIncidents.title",
        content: [
            {
                title: "guruIncidents.incident_reports.title",
                description: "guruIncidents.incident_reports.description",
                steps: "guruIncidents.incident_reports.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Incident%20Report.mp4",
            },
            {
                title: "guruIncidents.report_new_incident.title",
                description: "guruIncidents.report_new_incident.description",
                steps: "guruIncidents.report_new_incident.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Report%20New%20Incident.mp4",
            },
        ],
    },
    {
        scope: "guru_Operators",
        icon: operators,
        title: "guru_Operators.title",
        content: [
            {
                title: "guru_Operators.daily_operators_check.title",
                description: "guru_Operators.daily_operators_check.description",
                steps: "guru_Operators.daily_operators_check.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Daily%20Operater%20Check.mp4",
            },
            {
                title: "guru_Operators.unfinished_checks.title",
                description: "guru_Operators.unfinished_checks.description",
                steps: "guru_Operators.unfinished_checks.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Unfinished%20Checks.mp4",
            },
            {
                title: "guru_Operators.lookup_daily_checks.title",
                description: "guru_Operators.lookup_daily_checks.description",
                steps: "guru_Operators.lookup_daily_checks.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Lookup%20Daily%20Checks.mp4",
            },
            {
                title: "guru_Operators.annual_report.title",
                description: "guru_Operators.annual_report.description",
                steps: "guru_Operators.annual_report.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Annual%20Report.mp4",
            },
            {
                title: "guru_Operators.lookup_annual_reports.title",
                description: "guru_Operators.lookup_annual_reports.description",
                steps: "guru_Operators.lookup_annual_reports.steps",
                video: "https://2023aukaiprodv6.blob.core.windows.net/video/Lookup%20Annual%20Reports.mp4",
            },
        ],
    },
    {
        scope: "guru_fuel_tracking",
        icon: energy,
        title: "guru_fuel_tracking.title",
        content: [
            {
                title: "guru_fuel_tracking.fuel_tracking.title",
                description: "guru_fuel_tracking.fuel_tracking.description",
                steps: "guru_fuel_tracking.fuel_tracking.steps",
                video: "",
            },

            {
                title: "guru_fuel_tracking.add_new_trans.title",
                description: "guru_fuel_tracking.add_new_trans.description",
                steps: "guru_fuel_tracking.add_new_trans.steps",
                video: "",
            },

            {
                title: "guru_fuel_tracking.view_fuel_spending_hist.title",
                description: "guru_fuel_tracking.view_fuel_spending_hist.description",
                steps: "guru_fuel_tracking.view_fuel_spending_hist.steps",
                video: "",
            },

            {
                title: "guru_fuel_tracking.fuel_orders_hist.title",
                description: "guru_fuel_tracking.fuel_orders_hist.description",
                steps: "guru_fuel_tracking.fuel_orders_hist.steps",
                video: "",
            },
        ],
    },
    {
        scope: "guruDisposal",
        icon: disposal,
        title: "guruDisposal.title",
        content: [
            {
                title: "guruDisposal.vessel_removal.title",
                description: "guruDisposal.vessel_removal.description",
                steps: "guruDisposal.vessel_removal.steps",
                video: "",
            },
            {
                title: "guruDisposal.removal_history_disposal_details.title",
                description: "guruDisposal.removal_history_disposal_details.description",
                steps: "guruDisposal.removal_history_disposal_details.steps",
                video: "",
            },
        ],
    },

];
