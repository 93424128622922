import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import * as Constants from "../../../constants";
import { getAuthHeader } from "../../../helpers/Authorization";
import AnnualReportDetails from "./AnnualReportDetails";
import Table from "../../ShareComponents/Table/Table";
import VINLink from "../../ShareComponents/helpers/VINLink";
import ConsoleHelper from "../../../helpers/ConsoleHelper";
import "../../../styles/ShareComponents/Table/table.scss";

const AnnualInspectionsTable = ({ vehicle }) => {
  const [dataReady, setDataReady] = useState(false);
  const [inspections, setInspections] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [mobileDetails, setMobileDetails] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${Constants.MOBILE_BREAKPOINT}px)` });
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedInspection) {
      setMobileDetails(true);
    }
  }, [selectedInspection]);

  //{"error_code":"G-0","error_message_dev":"G-0 An exception occured. (Extra info: (1146, \"Table 'skyit_demo_3.api_dailyinspection' doesn't exist\"))","error_message_user":"Something went wrong. Please send homing pigeon to support for more information."}
  useEffect(() => {
    if (vehicle) {
      setSelectedInspection(null);
      const cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${Constants.ENDPOINT_PREFIX}/api/v1/Assets/Get/AnnualReport/${vehicle.VIN}`, {
          ...getAuthHeader(),
        })
        .then((response) => {
          const inspections = response.data;
          // console.log("inspection is", inspections);
          for (var i in inspections) {
            inspections[i].current_location = vehicle.current_location;
            inspections[i].id = inspections[i].file_id;
          }
          setInspections(inspections);
          setDataReady(true);
        })
        .catch((error) => {
          ConsoleHelper(error);
        });
      return () => {
        //Doing clean up work, cancel the asynchronous api call
        cancelTokenSource.cancel("cancel the asynchronous api call");
      };
    } else {
      setInspections(null);
      setDataReady(false);
    }
  }, [vehicle]);

  if (!inspections) return null;

  const statusType = {
    all: t("general.all"),
    damaged: t("lookupAnnualReportPanel.damaged"),
    undamaged: t("lookupAnnualReportPanel.undamaged"),
  };

  const statusItemTemplate = (value) => {
    return !value ? statusType.damaged : statusType.undamaged;
  };

  let tableHeaderTitles = [
    { header: t("general.id"), colFilter: { field: "file_id" } },
    { header: t("lookupAnnualReportPanel.vin_label"), colFilter: { field: "VIN" } },
    {
      header: t("lookupAnnualReportPanel.operator_label"),
      colFilter: { field: "created_by", filterOptions: { filterAs: "dropdown" } },
    },
    {
      header: t("lookupAnnualReportPanel.location_label"),
      colFilter: { field: "current_location", filterOptions: { filterAs: "dropdown" } },
    },
    {
      header: t("lookupAnnualReportPanel.date_completed_label"),
      colFilter: {
        field: "date_created",
        filterOptions: { filterAs: "dateRange", dateFormat: "YYYY-MM-DD" },
      },
    },
    {
      header: t("lookupAnnualReportPanel.status_label"),
      colFilter: {
        field: "is_tagout",
        filterOptions: {
          filterAs: "dropdown",
          valueTemplate: statusItemTemplate,
          itemTemplate: statusItemTemplate,
        },
      },
    },
    {
      header: t("general.modified_by"),
      colFilter: { field: "modified_by", filterOptions: { filterAs: "dropdown" } },
    },
  ];

  let tableData = inspections.map((inspection) => {
    return {
      id: inspection.file_id,
      dataPoint: inspection,
      cells: [
        inspection.id,
        <VINLink vin={inspection.VIN} />,
        inspection.created_by || t("general.not_applicable"),
        inspection.current_location,
        moment(inspection.inspection_date).format("YYYY-MM-DD"),
        inspection.vessel_damaged_since_last_report
          ? t("lookupAnnualReportPanel.damaged")
          : t("lookupAnnualReportPanel.undamaged"),
        inspection.modified_by || t("general.not_applicable"),
      ],
    };
  });

  return (
    <React.Fragment>
      {isMobile ? (
        selectedInspection && mobileDetails ? (
          <AnnualReportDetails
            vehicle={vehicle}
            inspection={selectedInspection}
            setSelectedInspection={setSelectedInspection}
            setMobileDetails={setMobileDetails}
          />
        ) : (
          <div className="darkTable">
            <Table
              dataReady={dataReady}
              tableHeaders={tableHeaderTitles}
              tableData={tableData}
              onSelectionChange={(inspection) => setSelectedInspection(inspection)}
              hasSelection
            />
          </div>
        )
      ) : (
        <div className="darkTable">
          <Table
            dataReady={dataReady}
            tableHeaders={tableHeaderTitles}
            tableData={tableData}
            onSelectionChange={(inspection) => {
              // console.log("Selected inspection:", inspection);
              setSelectedInspection(inspection);
            }}
            hasSelection
          />
          {selectedInspection && (
            <AnnualReportDetails
              vehicle={vehicle}
              inspection={selectedInspection}
              setSelectedInspection={setSelectedInspection}
              setMobileDetails={setMobileDetails}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AnnualInspectionsTable;
